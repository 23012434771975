import { Cut } from "@/types/player";
import { getTotalDurationOfCuts } from "./cuts";

export class FrameHelper {
  static convertFrameToRelative = (
    frame: number,
    cuts: Cut[],
    framerate: number
  ) => {
    if (cuts.length == 0) return frame;
    const currCutIdx = cuts.findIndex(
      (cut) => Math.round(cut.end * framerate) >= frame
    );

    // Fallback: If the frame is beyond the last cut, return the last cut's end frame
    if (currCutIdx == -1) {
      const totalDuration = getTotalDurationOfCuts(cuts);
      const frameToTime = frame / framerate;
      if (frameToTime > totalDuration) {
        return totalDuration * framerate - 1;
      }
    }

    if (currCutIdx == -1) {
      // debugger;
      throw new Error(
        `Frame ${frame} (${frame / framerate}) not found in cuts`
      );
    }

    const previousCuts = cuts.slice(0, currCutIdx);

    const totalPreviousFrameCount = Math.round(
      getTotalDurationOfCuts(previousCuts) * framerate
    );

    const frameOffsetInCurrentCut =
      frame - Math.round(cuts[currCutIdx].start * framerate);
    return totalPreviousFrameCount + frameOffsetInCurrentCut;
  };

  static convertFrameToRelativeCutFrame = (frame: number, cuts?: Cut[]) => {
    if (!cuts || cuts.length == 0) return frame;
    const currCutIdx = cuts.findIndex((cut) => cut.end >= frame);

    if (currCutIdx == -1) {
      // debugger;
      throw new Error(`Frame ${frame} (${frame}) not found in cuts`);
    }

    const previousCuts = cuts.slice(0, currCutIdx);

    const totalPreviousFrameCount = previousCuts.reduce(
      (acc, cut) => acc + (cut.end - cut.start) + 1,
      0
    );
    const frameOffsetInCurrentCut = frame - cuts[currCutIdx].start + 1;
    return totalPreviousFrameCount + frameOffsetInCurrentCut;
  };

  static convertFrameToAbsolute = (
    relativeFrame: number,
    cuts: Cut[],
    framerate: number
  ): number => {
    if (!cuts) return relativeFrame;
    if (cuts.length == 0) return relativeFrame;
    let accumulatedFrames = 0;

    for (let i = 0; i < cuts.length; i++) {
      const cutDurationFrames = Math.round(
        (cuts[i].end - cuts[i].start) * framerate
      );

      // If the relative frame is within the current cut's duration
      // console.log(
      //   `accumulatedFrames: ${accumulatedFrames} + ${cutDurationFrames} = ${
      //     accumulatedFrames + cutDurationFrames
      //   } > ${relativeFrame}`
      // );
      if (accumulatedFrames + cutDurationFrames >= relativeFrame) {
        const frameOffset = relativeFrame - accumulatedFrames;
        return Math.round(cuts[i].start * framerate) + frameOffset;
      }

      accumulatedFrames += cutDurationFrames;
    }
    console.assert(false, `Relative frame ${relativeFrame} not found in cuts`);
    debugger;
    return 0;
    // throw new Error(`Relative frame ${relativeFrame} not found in cuts`);
  };

  static syncTimecodeToFramerate(cuts: Cut[], framerate: number): Cut[] {
    const syncedCuts: Cut[] = [];
    for (const cut of cuts) {
      const syncedStart = Math.round(cut.start * framerate) / framerate;
      const syncedEnd = Math.round(cut.end * framerate) / framerate;
      syncedCuts.push({ start: syncedStart, end: syncedEnd });
    }

    const syncedCutsFiltered = syncedCuts.filter((cut) => cut.start < cut.end);
    return syncedCutsFiltered;
  }
}
