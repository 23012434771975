export const LANGUAGE_CLASSIFICATION = {
  latin: [
    "afrikaans",
    "catalan",
    "croatian",
    "czech",
    "danish",
    "dutch",
    "english",
    "estonian",
    "finnish",
    "french",
    "galician",
    "german",
    "hungarian",
    "icelandic",
    "indonesian",
    "italian",
    "latvian",
    "lithuanian",
    "malay",
    "maori",
    "norwegian",
    "polish",
    "portuguese",
    "romanian",
    "slovak",
    "slovenian",
    "spanish",
    "swahili",
    "swedish",
    "tagalog",
    "turkish",
    "vietnamese",
    "welsh",
  ],
  cyrillic: [
    "azerbaijani",
    "belarusian",
    "bosnian",
    "bulgarian",
    "kazakh",
    "macedonian",
    "russian",
    "serbian",
    "ukrainian",
  ],
  arabic: ["arabic", "persian", "urdu"],
  armenian: ["armenian"],
  chinese_characters: ["chinese"],
  devanagari: ["hindi", "marathi", "nepali"],
  greek: ["greek"],
  hebrew: ["hebrew"],
  japanese_characters: ["japanese"],
  hangul: ["korean"],
  kannada: ["kannada"],
  tamil: ["tamil"],
  thai: ["thai"],
};

// Whisper supported languages: https://help.openai.com/en/articles/7031512-whisper-api-faq
export const WHISPER_LANGUAGES = {
  en: "english",
  zh: "chinese",
  de: "german",
  es: "spanish",
  ru: "russian",
  ko: "korean",
  fr: "french",
  ja: "japanese",
  pt: "portuguese",
  tr: "turkish",
  pl: "polish",
  ca: "catalan",
  nl: "dutch",
  ar: "arabic",
  sv: "swedish",
  it: "italian",
  id: "indonesian",
  hi: "hindi",
  fi: "finnish",
  vi: "vietnamese",
  he: "hebrew",
  uk: "ukrainian",
  el: "greek",
  ms: "malay",
  cs: "czech",
  ro: "romanian",
  da: "danish",
  hu: "hungarian",
  ta: "tamil",
  no: "norwegian",
  th: "thai",
  ur: "urdu",
  hr: "croatian",
  bg: "bulgarian",
  lt: "lithuanian",
  la: "latin",
  mi: "maori",
  ml: "malayalam",
  cy: "welsh",
  sk: "slovak",
  te: "telugu",
  fa: "persian",
  lv: "latvian",
  bn: "bengali",
  sr: "serbian",
  az: "azerbaijani",
  sl: "slovenian",
  kn: "kannada",
  et: "estonian",
  mk: "macedonian",
  br: "breton",
  eu: "basque",
  is: "icelandic",
  hy: "armenian",
  ne: "nepali",
  mn: "mongolian",
  bs: "bosnian",
  kk: "kazakh",
  sq: "albanian",
  sw: "swahili",
  gl: "galician",
  mr: "marathi",
  pa: "punjabi",
  si: "sinhala",
  km: "khmer",
  sn: "shona",
  yo: "yoruba",
  so: "somali",
  af: "afrikaans",
  oc: "occitan",
  ka: "georgian",
  be: "belarusian",
  tg: "tajik",
  sd: "sindhi",
  gu: "gujarati",
  am: "amharic",
  yi: "yiddish",
  lo: "lao",
  uz: "uzbek",
  fo: "faroese",
  ht: "haitian creole",
  ps: "pashto",
  tk: "turkmen",
  nn: "nynorsk",
  mt: "maltese",
  sa: "sanskrit",
  lb: "luxembourgish",
  my: "myanmar",
  bo: "tibetan",
  tl: "tagalog",
  mg: "malagasy",
  as: "assamese",
  tt: "tatar",
  haw: "hawaiian",
  ln: "lingala",
  ha: "hausa",
  ba: "bashkir",
  jw: "javanese",
  su: "sundanese",
};

export let TO_LANGUAGE_CODE: any = {};

for (const [code, language] of Object.entries(WHISPER_LANGUAGES)) {
  TO_LANGUAGE_CODE[language] = code;
}

// language aliases
TO_LANGUAGE_CODE = {
  ...TO_LANGUAGE_CODE,
  burmese: "my",
  valencian: "ca",
  flemish: "nl",
  haitian: "ht",
  letzeburgesch: "lb",
  pushto: "ps",
  panjabi: "pa",
  moldavian: "ro",
  moldovan: "ro",
  sinhalese: "si",
  castilian: "es",
};

export const RTL_LANGUAGES = [
  "ar", // Arabic
  "he", // Hebrew
  "fa", // Persian
  "ur", // Urdu
  "yi", // Yiddish
  "sd", // Sindhi
  "ps", // Pashto
  "ha", // Hausa
];
