import { Position } from "../types/layer";
import { Crop, Cut } from "../types/player";
import { BBox, TrackingObject } from "../types/tracking";
import { isRangeOverlapping } from "../utils/array";
import { convertToFrameBasedCuts } from "./cuts";
import { FrameHelper } from "./frame";

export const frameToTime = (frame: number, fps: number) => {
  return frame / fps;
};

export const filterCropsInCut = (crops: Crop[], cut: Cut, fps: number) => {
  return crops.filter((crop) => {
    const cutStartFrame = Math.round(cut.start * fps);
    const cutEndFrame = Math.round(cut.end * fps);

    return crop.endFrame > cutStartFrame && crop.startFrame < cutEndFrame;
  });
};

// export const distributeCrops = (crops: Crop[]) => {
//   const finalCrops: Crop[] = [];
//   const groups = groupOverlappingCrops(crops);

//   groups.map((group, i) => {
//     // deep copy
//     const _group = JSON.parse(JSON.stringify(group));

//     // console.log("GROUP ", group);
//     if (_group.length > 3) return;
//     if (i == groups.length - 1) return;
//     if (_group.length == 1) return finalCrops.push(_group[0]);

//     const intersection = _group[0].end_frame - _group[1].start_frame;
//     // console.log("INT ", intersection);

//     _group[0].end_frame -= intersection / 2;
//     _group[1].start_frame += intersection / 2;

//     finalCrops.push(...[_group[0], _group[1]]);
//   });
//   // console.log(finalCrops);
//   // return crops;
//   return finalCrops;
// };

export const distributeCrops = (crops: Crop[], fps: number): Crop[] => {
  const TARGET_DURATION_SEC = 5;
  const targetDurationFrames = TARGET_DURATION_SEC * fps;
  const finalCrops: Crop[] = [];

  const getGroupIntersection = (group: Crop[]) => {
    return {
      startFrame: Math.max(group[0].startFrame, group[1].startFrame),
      endFrame: Math.min(group[0].endFrame, group[1].endFrame),
    };
  };

  const chunkGroup = (
    group: Crop[],
    intersection: { startFrame: number; endFrame: number }
  ) => {
    const totalFrames = intersection.endFrame - intersection.startFrame;
    const nChunk = Math.round(totalFrames / targetDurationFrames);
    const chunkSize = totalFrames / nChunk;
    const chunks = [];

    for (let i = 0; i < nChunk; i++) {
      chunks.push({
        id: crypto.randomUUID(),
        startFrame: intersection.startFrame + i * chunkSize,
        endFrame: intersection.startFrame + (i + 1) * chunkSize,
        // cropBbox: i % 2 === 0 ? group[0].cropBbox : group[1].cropBbox,
        position: i % 2 === 0 ? group[0].position : group[1].position,
        scale: i % 2 === 0 ? group[0].scale : group[1].scale,
      });
    }

    return chunks;
  };

  const getGaps = (crops: Crop[]) => {
    const gaps = [];
    for (let i = 0; i < crops.length - 1; i++) {
      if (crops[i].endFrame < crops[i + 1].startFrame) {
        gaps.push({
          startFrame: crops[i].endFrame,
          endFrame: crops[i + 1].startFrame,
        });
      }
    }
    return gaps;
  };

  const fillGaps = (gaps: any[], originalCrops: Crop[]) => {
    gaps.forEach((gap) => {
      originalCrops.forEach((crop) => {
        if (
          crop.startFrame >= gap.startFrame &&
          crop.endFrame <= gap.endFrame
        ) {
          finalCrops.push({
            ...crop,
            startFrame: gap.startFrame,
            endFrame: gap.endFrame,
          });
        }
      });
    });
  };

  const groups = groupOverlappingCrops(crops);
  groups.forEach((group) => {
    if (group.length > 3) return;

    if (group.length === 1) {
      finalCrops.push(group[0]);
      return;
    }

    const groupIntersection = getGroupIntersection(group);
    const chunkedGroup = chunkGroup(group, groupIntersection);
    finalCrops.push(...chunkedGroup);
  });

  const gaps = getGaps(finalCrops);
  fillGaps(gaps, crops);

  // Ensure crops are sorted
  finalCrops.sort((a, b) => a.startFrame - b.startFrame);

  return finalCrops;
};

// const isOverlapping = (crop: Crop, crops: Crop[]): boolean => {
//   for (let existingCrop of crops) {
//     if (
//       (crop.start_frame >= existingCrop.start_frame &&
//         crop.start_frame < existingCrop.end_frame) ||
//       (crop.end_frame > existingCrop.start_frame &&
//         crop.end_frame <= existingCrop.end_frame)
//     ) {
//       return true;
//     }
//   }
//   return false;
// };

/**
 * Checks if two crops overlap by frame.
 *
 * @param a First crop.
 * @param b Second crop.
 * @returns `true` if the crops overlap, `false` otherwise.
 */
function isOverlapping(a: Crop, b: Crop): boolean {
  return !(a.endFrame < b.startFrame || b.endFrame < a.startFrame);
}

/**
 * Groups crops by overlapping frames.
 *
 * @param crops List of crops.
 * @returns List of groups of overlapping crops.
 */
export function groupOverlappingCrops(crops: Crop[]): Crop[][] {
  // Sort crops by their start_frame.
  const sortedCrops = crops.sort((a, b) => a.startFrame - b.startFrame);

  const groupedCrops: Crop[][] = [];
  let currentGroup: Crop[] = [];

  for (let i = 0; i < sortedCrops.length; i++) {
    const currentCrop = sortedCrops[i];
    let hasOverlap = false;

    for (const groupCrop of currentGroup) {
      if (isOverlapping(currentCrop, groupCrop)) {
        hasOverlap = true;
        break;
      }
    }

    // If there's no current group or the current crop has overlap with any member of the group.
    if (!currentGroup.length || hasOverlap) {
      currentGroup.push(currentCrop);
    } else {
      // Otherwise, start a new group.
      groupedCrops.push(currentGroup);
      currentGroup = [currentCrop];
    }

    // If it's the last crop, add the current group to groupedCrops.
    if (i === sortedCrops.length - 1) {
      groupedCrops.push(currentGroup);
    }
  }

  return groupedCrops;
}

export function groupCropByRow(crops: Crop[]): Crop[][] {
  const rows: Crop[][] = [];

  for (const crop of crops) {
    const row = rows.find((row) => {
      return row.every((c) => !isOverlapping(c, crop));
    });

    if (row) {
      row.push(crop);
    } else {
      rows.push([crop]);
    }
  }

  return rows;
}

export function groupDirectOverlappingCrops(crops: Crop[]): Crop[][] {
  // Sort crops by their start_frame.
  const sortedCrops = crops.sort((a, b) => a.startFrame - b.startFrame);

  const groupedCrops: Crop[][] = [];
  let currentGroup: Crop[] = [];

  for (let i = 0; i < sortedCrops.length; i++) {
    const currentCrop = sortedCrops[i];
    let hasOverlap = currentGroup.every((crop) =>
      isOverlapping(currentCrop, crop)
    );

    if (!currentGroup.length || hasOverlap) {
      currentGroup.push(currentCrop);
    } else {
      // Otherwise, start a new group.
      groupedCrops.push(currentGroup);
      currentGroup = [currentCrop];
    }

    // If it's the last crop, add the current group to groupedCrops.
    if (i === sortedCrops.length - 1) {
      groupedCrops.push(currentGroup);
    }
  }

  return groupedCrops;
}

// You can test the function with your crops to see if it groups correctly now.

export type IndexedCrop = Crop & { index: number };
export const convertCropsToRelative = (
  crops: Crop[],
  cuts: Cut[],
  fps: number
): IndexedCrop[] => {
  if (cuts.length === 0)
    return crops.map((crop, index) => ({ ...crop, index }));
  // debugger;
  const frameBasedCuts = convertToFrameBasedCuts(cuts, fps);

  crops?.sort((a, b) => a.startFrame - b.startFrame);
  const indexedCrops = crops?.map((crop, index) => ({ ...crop, index }));
  // debugger;

  const filteredCrops: IndexedCrop[] = frameBasedCuts
    .map((cut) => {
      const overlappingCrops = indexedCrops?.filter((crop) => {
        return isRangeOverlapping(
          [crop.startFrame, crop.endFrame - 1],
          [cut.startFrame, cut.endFrame - 1]
        );
      });

      return overlappingCrops?.map(
        (crop, i) =>
          ({
            ...crop,
            startFrame:
              i === 0 && crop.startFrame < cut.startFrame
                ? cut.startFrame
                : crop.startFrame,
            endFrame:
              i === overlappingCrops.length - 1 && crop.endFrame > cut.endFrame
                ? cut.endFrame
                : crop.endFrame,
          } as IndexedCrop)
      );
    })
    .flat();

  // merge crops with same id (in case they were split by a cut)
  const mergedCrops: IndexedCrop[] = [];
  filteredCrops.forEach((crop) => {
    const existingCrop = mergedCrops.find((c) => c.index === crop.index);
    if (!existingCrop) return mergedCrops.push(crop);
    existingCrop.startFrame = Math.min(
      existingCrop.startFrame,
      crop.startFrame
    );
    existingCrop.endFrame = Math.max(existingCrop.endFrame, crop.endFrame);
  });
  // debugger;
  return mergedCrops.map((crop) => ({
    ...crop,
    startFrame: FrameHelper.convertFrameToRelative(crop.startFrame, cuts, fps),
    endFrame: FrameHelper.convertFrameToRelative(crop.endFrame, cuts, fps),
  })) as IndexedCrop[];
};
// Deprecated ? (not used)
export const getOriginalCrops = (
  crops: Crop[],
  cuts: Cut[],
  fps: number
): Crop[] => {
  return crops.map((crop) => {
    let currentSeekDurationInFrame = 0;
    let hasProcessedCrop = false;
    let adjustedStartFrame = crop.startFrame;
    let adjustedEndFrame = crop.endFrame;

    for (let i = 0; i < cuts.length && !hasProcessedCrop; i++) {
      const cutStartFrame = Math.round(cuts[i].start * fps);
      const cutEndFrame = Math.round(cuts[i].end * fps);
      const cutDuration = cutEndFrame - cutStartFrame;

      if (adjustedStartFrame <= cutDuration + currentSeekDurationInFrame) {
        const seekInCut = adjustedStartFrame - currentSeekDurationInFrame - 1;

        adjustedStartFrame = cutStartFrame + seekInCut;
        adjustedEndFrame =
          adjustedStartFrame + (crop.endFrame - crop.startFrame);
        hasProcessedCrop = true;
      } else {
        currentSeekDurationInFrame += cutDuration;
      }
    }

    if (!hasProcessedCrop) {
      adjustedStartFrame -= currentSeekDurationInFrame;
      adjustedEndFrame -= currentSeekDurationInFrame;
    }

    return {
      ...crop,
      startFrame: adjustedStartFrame,
      endFrame: adjustedEndFrame,
    };
  });
};

export const convertCropsToAbsolute = (
  crops: Crop[],
  cuts: Cut[],
  fps: number
): Crop[] => {
  return crops.map((crop) => {
    const startFrame = FrameHelper.convertFrameToAbsolute(
      crop.startFrame,
      cuts,
      fps
    );

    const endFrame = FrameHelper.convertFrameToAbsolute(
      crop.endFrame,
      cuts,
      fps
    );

    return {
      ...crop,
      startFrame: startFrame,
      endFrame: endFrame,
    };
  });
};

export const trackingObjectsWithinCrop = (
  crop: Crop,
  trackingObjects: TrackingObject[]
) => {
  return trackingObjects
    .filter(
      (object) =>
        crop.startFrame >= object.startFrame && crop.endFrame <= object.endFrame
    )
    .sort((a, b) => {
      const aX = a.averageBbox[2] - a.averageBbox[0] / 2;
      const bX = b.averageBbox[2] - b.averageBbox[0] / 2;

      return aX - bX;
    });
};

export const trackingObjectToCropBbox = (
  trackingObject: TrackingObject,
  originalRatio = 16 / 9
): BBox => {
  const fullHeight = 1; // As a percentage
  const width = (9 / 16) * fullHeight;

  // Calculate center of the face
  const faceCenterX =
    (trackingObject.averageBbox[0] + trackingObject.averageBbox[2]) / 2;
  // Calculate the left and right percentage
  const leftPercentage = Math.max(0, faceCenterX - width / 2 / originalRatio);
  const rightPercentage = Math.min(1, faceCenterX + width / 2 / originalRatio);
  // // console.log("face cneter", faceCenterX, [
  //   leftPercentage,
  //   0,
  //   rightPercentage,
  //   1,
  // ]);
  return [leftPercentage, 0, rightPercentage, 1];
};

export const getCenteredCropBbox = (
  originalRatio: number = 16 / 9,
  outputRatio: number = 9 / 16
): BBox => {
  // Calculate the relative crop dimensions based on original and output ratios
  const relativeCropWidth =
    originalRatio > outputRatio ? outputRatio / originalRatio : 1;
  const relativeCropHeight =
    originalRatio > outputRatio ? 1 : originalRatio / outputRatio;

  // Define the center of the crop
  const centerX = 0.5;
  const centerY = 0.5;

  // Determine the bbox based on the center and dimensions
  const x1 = centerX - relativeCropWidth / 2;
  const y1 = centerY - relativeCropHeight / 2;
  const x2 = centerX + relativeCropWidth / 2;
  const y2 = centerY + relativeCropHeight / 2;

  return [x1, y1, x2, y2];
};

export const createBBoxFromPosition = (
  position: Position,
  containerWidth: number,
  containerHeight: number,
  cropWidthPx: number,
  cropHeightPx: number
): BBox => {
  // Convert crop dimensions from pixels to relative values (0 to 1)
  const cropWidth = cropWidthPx / containerWidth;
  const cropHeight = cropHeightPx / containerHeight;

  // Calculate the top-left corner of the bbox
  let x1 = position.x - cropWidth / 2;
  let y1 = position.y - cropHeight / 2;

  // Adjust the bbox to ensure it remains within the container boundaries
  x1 = Math.max(0, Math.min(x1, 1 - cropWidth));
  y1 = Math.max(0, Math.min(y1, 1 - cropHeight));

  // Calculate the bottom-right corner of the bbox
  const x2 = x1 + cropWidth;
  const y2 = y1 + cropHeight;

  // console.log("createBBoxFromPosition", [x1, y1, x2, y2]);

  return [x1, y1, x2, y2];
};

export const convertBboxToPosition = (bbox: BBox): Position => {
  return {
    // Center point of the crop bbox
    x: (bbox[0] + bbox[2]) / 2,
    y: (bbox[1] + bbox[3]) / 2,
  };
};

export const normalizeOverlappingCrops = (crops: Crop[]): Crop[] => {
  const normalizedCrops: Crop[] = [...crops];
  normalizedCrops.sort((a, b) => a.startFrame - b.startFrame);

  // Check and adjust overlaps
  for (let i = 0; i < normalizedCrops.length - 1; i++) {
    const currentCrop = normalizedCrops[i];
    const nextCrop = normalizedCrops[i + 1];

    if (currentCrop.endFrame > nextCrop.startFrame) {
      // Calculate the middle frame of the overlap
      const middleFrame = Math.floor(
        (currentCrop.endFrame + nextCrop.startFrame) / 2
      );

      // Adjust the endFrame of the current crop and the startFrame of the next crop
      currentCrop.endFrame = middleFrame;
      nextCrop.startFrame = middleFrame + 1;
    }

    // if (normalizedCrops[i].endFrame >= normalizedCrops[i + 1].endFrame) {
    //   normalizedCrops[i].endFrame = normalizedCrops[i + 1].endFrame - 1;
    // }
  }

  return normalizedCrops;
};

export const removeTooShortCrops = (crops: Crop[], fps: number): Crop[] => {
  const MIN_DURATION_SEC = 0.1;
  const minDurationFrames = MIN_DURATION_SEC * fps;

  return crops.filter(
    (crop) => crop.endFrame - crop.startFrame > minDurationFrames
  );
};

// export const normalizeCrops = (crops: Crop[], fps: number): Crop[] => {
//   return normalizeOverlappingCrops(removeTooShortCrops(crops, fps));
// };

export const normalizeCrops = (crops: Crop[], oldCrops: Crop[]): Crop[] => {
  return normalizeMaxOverlappingCrops(
    normalizeFollowingCrops(crops, oldCrops)
    // Filter out crops that are too short
  ).filter((c) => c.endFrame - c.startFrame > 1);
};

export const normalizeFollowingCrops = (
  crops: Crop[],
  oldCrops: Crop[] = crops
) => {
  // Sort crops by startFrame for sequential access

  const rows: Crop[][] = groupCropByRow(oldCrops);

  for (const row of rows) {
    row.sort((a, b) => a.startFrame - b.startFrame && a.endFrame - b.endFrame);
    for (let i = 0; i < row.length - 1; i++) {
      const oldCrop = oldCrops.find((crop) => crop.id === row[i].id);
      if (!oldCrop) continue;

      const newCrop = crops.find((crop) => crop.id === row[i].id);
      if (!newCrop) continue;
      const startChange = newCrop.startFrame !== oldCrop.startFrame;
      const endChange = newCrop.endFrame !== oldCrop.endFrame;

      if (!startChange && !endChange) continue;

      row.forEach(() => {
        const hasOverlap = row
          .filter((c) => c.id != newCrop.id)
          .find((c) =>
            isRangeOverlapping(
              [c.startFrame, c.endFrame],
              [newCrop.startFrame, newCrop.endFrame]
            )
          );

        if (!hasOverlap) return;

        const startOverlap = newCrop.startFrame <= hasOverlap.endFrame;
        const endOverlap = newCrop.endFrame >= hasOverlap.startFrame;

        if (!startOverlap && !endOverlap) return;

        if (startOverlap && startChange) {
          newCrop.startFrame = hasOverlap.endFrame + 1;
        }
        if (endOverlap && endChange) {
          newCrop.endFrame = hasOverlap.startFrame - 1;
        }
      });
    }
  }

  return crops;
};

export const normalizeMaxOverlappingCrops = (crops: Crop[]) => {
  const MAX = 3;
  const overlappingCrops = groupDirectOverlappingCrops(crops);
  console.log(overlappingCrops);
  console.log(crops);
  const toRemoveCropIds = new Set();

  for (const overlappingCrop of overlappingCrops) {
    if (overlappingCrop.length > MAX) {
      overlappingCrop.sort((a, b) => a.endFrame - b.endFrame);

      // Remove every crop after MAX
      for (let i = MAX; i < overlappingCrop.length; i++) {
        toRemoveCropIds.add(overlappingCrop[i].id);
      }
    }
  }

  return crops.filter((crop) => !toRemoveCropIds.has(crop.id));
};

export type FaceTrackingObject = {
  id: string;
  type: "face";
  averageBbox: [number, number, number, number];
  startFrame: number;
  endFrame: number;
  thumbnailUrl?: string;
};

export type TrackedFace = {
  id: string;
  frame: number;
  face_bbox: [number, number, number, number];
};

export const computeCropsFromTrackingObjects = (
  trackingObjects: FaceTrackingObject[],
  originalRatio: number,
  originalFps: number
): Crop[] => {
  if (trackingObjects.length === 0) return [];

  type TimeChunk = {
    startFrame: number;
    endFrame: number;
    trackingObjectCandidates: FaceTrackingObject[];
  };

  const trackingObjectBounds = [
    ...new Set(
      trackingObjects
        .map((obj) => [obj.startFrame, obj.endFrame] as [number, number])
        .flat()
    ),
  ].sort((a, b) => a - b);
  // // console.log(trackingObjectBounds);
  // creact chunks at bounds
  let chunks: TimeChunk[] = [];
  let lastChunkEndFrame = Number.NEGATIVE_INFINITY;
  for (let i = 0; i < trackingObjectBounds.length - 1; i++) {
    // offset ensures that the start frame of the next chunk is not the same as the end frame of the previous chunk
    const offset = trackingObjectBounds[i] == lastChunkEndFrame ? 1 : 0;
    const startFrame = trackingObjectBounds[i] + offset;
    const endFrame = trackingObjectBounds[i + 1];
    if (startFrame == endFrame) continue;
    const trackingObjectCandidates = trackingObjects.filter(
      (obj) => obj.startFrame <= startFrame && obj.endFrame >= endFrame
    );
    if (trackingObjectCandidates.length === 0) continue;
    chunks.push({
      startFrame,
      endFrame,
      trackingObjectCandidates,
    });
    lastChunkEndFrame = endFrame;
  }

  // smooth out chunks
  const maxChunkDurationFrame = Math.round(2.5 * originalFps);
  // // console.log("Before smooth:", chunks.length);
  for (let pass = 0; pass < 3; pass++) {
    // if chunk is too short, merge with previous chunk if they have the same tracking object candidates
    for (let i = 1; i < chunks.length; i++) {
      const candidatesMatch = chunks[i].trackingObjectCandidates.filter((c) =>
        chunks[i - 1].trackingObjectCandidates.includes(c)
      );
      // // console.log("Candidates match:", candidatesMatch.length);
      if (
        chunks[i].endFrame - chunks[i].startFrame < maxChunkDurationFrame &&
        candidatesMatch.length > 0
      ) {
        // // console.log("Found chunk to merge", chunks[i - 1], chunks[i]);
        chunks[i - 1].endFrame = chunks[i].endFrame;
        chunks[i - 1].trackingObjectCandidates = candidatesMatch;
        chunks.splice(i, 1);
        i--;
      }
    }
    // // console.log("After merge:", chunks.length);
  }
  // // console.log("After smooth:", chunks.length);

  // // console.log("Before filter", chunks.length);
  // filter out chunks that are too short
  chunks = chunks.filter(
    (chunk) =>
      chunk.endFrame - chunk.startFrame >= Math.round(0.5 * originalFps)
  );
  // // console.log("After filter", chunks.length);

  // // console.log("Before split:", chunks.length);

  // split chunks longer than 6 seconds into 3 seconds chunks

  const chunkWithSplits = chunks.map((chunk) => {
    if (
      chunk.endFrame - chunk.startFrame >=
        Math.round(1.5 * maxChunkDurationFrame) &&
      chunk.trackingObjectCandidates.length >= 2
    ) {
      const nChunk = Math.ceil(
        (chunk.endFrame - chunk.startFrame) / maxChunkDurationFrame
      );
      const chunkLength = Math.ceil(
        (chunk.endFrame - chunk.startFrame) / nChunk
      );
      const newChunks = [];
      for (let i = 0; i < nChunk; i++) {
        newChunks.push({
          startFrame: chunk.startFrame + i * chunkLength + 1,
          endFrame: chunk.startFrame + (i + 1) * chunkLength,
          trackingObjectCandidates: chunk.trackingObjectCandidates,
        });
      }
      return newChunks;
    } else {
      return [chunk];
    }
  });

  // // console.log("chunkWithSplits", chunkWithSplits);
  chunks = chunkWithSplits.flat();

  // // console.log("After split:", chunks.length);
  // // console.log(chunks);

  // Calculate crops for each chunk
  const lastCandidateIds: string[] = [];

  const crops = chunks.map((chunk, idx) => {
    const { startFrame, endFrame, trackingObjectCandidates } = chunk;

    // ensure bigger candidates are prioritzed
    const calculateArea = (bbox: [number, number, number, number]) =>
      (bbox[2] - bbox[0]) * (bbox[3] - bbox[1]);

    trackingObjects.sort(
      (a, b) => calculateArea(b.averageBbox) - calculateArea(a.averageBbox)
    );
    // get least recently used candidate
    trackingObjectCandidates.sort(
      (a, b) =>
        lastCandidateIds.lastIndexOf(a.id) - lastCandidateIds.lastIndexOf(b.id)
    );
    // console.log("lastCandidateIds", lastCandidateIds);
    // console.log(trackingObjectCandidates.map((c) => c.id));
    const candidate = trackingObjectCandidates[0];
    // // console.log("candidate", candidate.id);

    lastCandidateIds.push(candidate.id);

    const fullHeight = 1; // As a percentage
    const width = (9 / 16) * fullHeight;

    // Calculate center of the face
    let faceCenterX = (candidate.averageBbox[0] + candidate.averageBbox[2]) / 2;

    // Calculate the left and right percentage
    let leftPercentage = faceCenterX - width / 2 / originalRatio;
    let rightPercentage = faceCenterX + width / 2 / originalRatio;

    // Adjust if going beyond the image boundaries
    if (leftPercentage < 0) {
      faceCenterX -= leftPercentage;
      leftPercentage = 0;
    }
    if (rightPercentage > 1) {
      faceCenterX -= rightPercentage - 1;
      rightPercentage = 1;
    }

    // Recalculate the left and right percentages after adjusting the faceCenterX
    leftPercentage = Math.max(0, faceCenterX - width / 2 / originalRatio);
    rightPercentage = Math.min(1, faceCenterX + width / 2 / originalRatio);
    // // console.log("crop", {
    //   startFrame: startFrame,
    //   endFrame: endFrame,
    //   cropBbox: [leftPercentage, 0, rightPercentage, 1],
    // });
    if (idx === 0) {
      // console.log("RESULT");
      // console.log({
      //   startFrame: startFrame,
      //   endFrame: endFrame,
      //   cropBbox: [leftPercentage, 0, rightPercentage, 1],
      // });
    }
    return {
      startFrame: startFrame,
      endFrame: endFrame,
      position: {
        x: leftPercentage + (rightPercentage - leftPercentage) / 2,
        y: 0.5,
      },
      // cropBbox: [leftPercentage, 0, rightPercentage, 1],
    } as Crop;
  });
  // console.log(crops);
  return crops;
};

export const deflickerizedCrops = (
  crops: Crop[],
  cuts: Cut[],
  fps: number
): Crop[] => {
  // const MIN_GAP_DURATION_FRAMES = 2 * fps; // Minimum gap between two crops in terms of frames.
  const MAGNET_THRESHOLD_FRAMES = 1.0 * fps; // The threshold below which we'll "magnet" two crops together.
  const MIN_CROP_DURATION_FRAMES = 1.5 * fps; // Minimum duration a crop must have after deflickerization.

  const result: Crop[] = [];
  const relativeCrops = convertCropsToRelative(crops, cuts, fps);

  // Adjust the start frame of each crop based on the magnet logic.
  for (let i = 0; i < relativeCrops.length; i++) {
    const prevCrop = i > 0 ? relativeCrops[i - 1] : null;
    const currentCrop = { ...relativeCrops[i] }; // Create a deep copy of the current crop.

    const gapFrames = prevCrop
      ? currentCrop.startFrame - (prevCrop.endFrame ?? 0)
      : currentCrop.startFrame;

    // if (i == 0) console.log(gapFrames);
    if (gapFrames < MAGNET_THRESHOLD_FRAMES) {
      currentCrop.startFrame = prevCrop ? prevCrop.endFrame + 1 : 0; // (+1) prevent crop overlaps
    }
    // else if (gapFrames < MIN_GAP_DURATION_FRAMES) {
    //   currentCrop.startFrame += MIN_GAP_DURATION_FRAMES - gapFrames;
    //   if (currentCrop.startFrame > currentCrop.endFrame) continue; //if after adfjusting frame endframe > startframe, then remove crop
    // }

    result.push(currentCrop);
  }
  // debugger;
  // return convertCropsToAbsolute(result, cuts, fps);

  // // Filter out isolated crops that are too short.
  return convertCropsToAbsolute(result, cuts, fps).filter(
    (crop, idx, array) => {
      const prevCrop = idx > 0 ? array[idx - 1] : null;
      const nextCrop = idx < array.length - 1 ? array[idx + 1] : null;

      const prevGapFrames = prevCrop
        ? crop.startFrame - prevCrop.endFrame
        : Infinity;
      const nextGapFrames = nextCrop
        ? nextCrop.startFrame - crop.endFrame
        : Infinity;
      const cropDuration = crop.endFrame - crop.startFrame;

      // Condition for crop removal:
      // The crop is isolated (i.e. not "magnetized" to its neighbors)
      // and its duration is shorter than the minimum duration.
      return !(
        prevGapFrames > 1 &&
        nextGapFrames > 1 &&
        cropDuration < MIN_CROP_DURATION_FRAMES
      );
    }
  );
};

export const convertCropToRatio = (
  crop: Crop,
  originalRatio: number,
  ratio: number
) => {
  const fullHeight = 1; // As a percentage
  const width = ratio * fullHeight;

  // Calculate center of the face
  // let faceCenterX = (crop.cropBbox[0] + crop.cropBbox[2]) / 2;
  let faceCenterX = crop.position.x;

  // Calculate the left and right percentage
  let leftPercentage = faceCenterX - width / 2 / originalRatio;
  let rightPercentage = faceCenterX + width / 2 / originalRatio;

  // Adjust if going beyond the image boundaries
  if (leftPercentage < 0) {
    faceCenterX -= leftPercentage;
    leftPercentage = 0;
  }
  if (rightPercentage > 1) {
    faceCenterX -= rightPercentage - 1;
    rightPercentage = 1;
  }

  // Recalculate the left and right percentages after adjusting the faceCenterX
  leftPercentage = Math.max(0, faceCenterX - width / 2 / originalRatio);
  rightPercentage = Math.min(1, faceCenterX + width / 2 / originalRatio);
  // // console.log("crop", {
  //   startFrame: startFrame,
  //   endFrame: endFrame,
  //   cropBbox: [leftPercentage, 0, rightPercentage, 1],
  // });

  return {
    ...crop,
    cropBbox: [leftPercentage, 0, rightPercentage, 1],
  } as Crop;
};
