export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

type Range = [number, number];

export function isRangeOverlapping(range1: Range, range2: Range): boolean {
  let [a, b] = range1;
  let [c, d] = range2;

  // Check if they do not overlap and return the inverse of that
  return !(a > d || c > b);
}

export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}
